import $$ from 'dom7';
import Framework7 from './framework7-custom.js';

// Import F7 Styles
import '../css/framework7-custom.less';

// Import Icons and App Custom Styles
import '../../node_modules/@fortawesome/fontawesome-free/css/all.css';
import '../css/icons.css';
import '../css/app.less';

// Import Routes
import routes from './routes.js';

// Import main app component
import App from '../app.f7.html';

var app = new Framework7({
  root: '#app', // App root element
  component: App, // App main component

  name: 'F7 Progressive Web App', // App name
  theme: 'auto', // Automatic theme detection
  // App root data
  autoDarkTheme: 'true',
  touch: {
      iosTouchRipple: 'true',
  },

  // App routes
  routes: routes,
  // Register service worker
//  serviceWorker: {
//    path: '/service-worker.js',
//  },
});
//
////uses document because document will be topmost level in bubbling
//$(document).on('touchmove',function(e){
//  e.preventDefault();
//});
////uses body because jquery on events are called off of the element they are
////added to, so bubbling would not work if we used document instead.
//$('body').on('touchstart','.scrollable',function(e) {
//  if (e.currentTarget.scrollTop === 0) {
//    e.currentTarget.scrollTop = 1;
//  } else if (e.currentTarget.scrollHeight === e.currentTarget.scrollTop + e.currentTarget.offsetHeight) {
//    e.currentTarget.scrollTop -= 1;
//  }
//});
////prevents preventDefault from being called on document if it sees a scrollable div
//$('body').on('touchmove','.scrollable',function(e) {
//  e.stopPropagation();
//});