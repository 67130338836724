import $$ from 'dom7';

import HomePage from '../pages/home.f7.html';
import ProjectInfoPage from '../pages/project-info.f7.html';
import AlertsPage from '../pages/alerts.f7.html';
import HotlinePage from '../pages/hotline.f7.html';
import PhaseA1Page from '../pages/phaseA1.f7.html';
import PhaseA2Page from '../pages/phaseA2.f7.html';
import PhaseBPage from '../pages/phaseB.f7.html';
import PhaseC1Page from '../pages/phaseC1.f7.html';
import PhaseC2Page from '../pages/phaseC2.f7.html';
import PhaseDPage from '../pages/phaseD.f7.html';
import PhaseE1Page from '../pages/phaseE1.f7.html';
import PhaseE2Page from '../pages/phaseE2.f7.html';
import PhaseE3Page from '../pages/phaseE3.f7.html';
import FaqPage from '../pages/faq.f7.html';

import NotFoundPage from '../pages/404.f7.html';

var routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/project-info/',
    component: ProjectInfoPage,
    on: {
        pageInit: function () {
          console.log('Project Info page initialized');
        },
    },
  },
  {
    path: '/alerts/',
    component: AlertsPage,
  },
  {
    path: '/hotline/',
    component: HotlinePage,
    on: {
        pageInit: function () {
          console.log('Hotline page initialized');
        },
    },
  },
  {
    path: '/phase-a1/',
    component: PhaseA1Page,
    on: {
        pageInit: function () {
          console.log('Phase A1 page initialized');
        },
    },
  },
  {
    path: '/phase-a2/',
    component: PhaseA2Page,
    on: {
        pageInit: function () {
          console.log('Phase A2 page initialized');
        },
    },
  },
  {
    path: '/phase-b/',
    component: PhaseBPage,
    on: {
        pageInit: function () {
          console.log('Phase B page initialized');
        },
    },
  },
  {
    path: '/phase-c1/',
    component: PhaseC1Page,
    on: {
        pageInit: function () {
          console.log('Phase C1 page initialized');
        },
    },
  },
  {
    path: '/phase-c2/',
    component: PhaseC2Page,
    on: {
        pageInit: function () {
          console.log('Phase C2 page initialized');
        },
    },
  },
  {
    path: '/phase-d/',
    component: PhaseDPage,
    on: {
        pageInit: function () {
          console.log('Phase D page initialized');
        },
    },
  },
  {
    path: '/phase-e1/',
    component: PhaseE1Page,
    on: {
        pageInit: function () {
          console.log('Phase E1 page initialized');
        },
    },
  },
  {
    path: '/phase-e2/',
    component: PhaseE2Page,
    on: {
        pageInit: function () {
          console.log('Phase E2 page initialized');
        },
    },
  },
  {
    path: '/phase-e3/',
    component: PhaseE3Page,
    on: {
        pageInit: function () {
          console.log('Phase E3 page initialized');
        },
    },
  },
  {
    path: '/faq/',
    component: FaqPage,
    on: {
        pageInit: function () {
          console.log('FAQ page initialized');
        },
        pageAfterOut: function () {
          console.log('FAQ page out of view');
             
        },
        pageBeforeOut: function () {
          console.log('FAQ page is about to leave view');
        }
    },
  },
  {
    path: '(.*)',
    component: NotFoundPage,
    on: {
        pageInit: function () {
          console.log('Not Found page initialized');
        },
    },
  },
];

export default routes;